import React, { useState } from "react";
import "../css/menu.css";
import NavDecide from "../NavDecide";
import { Grid, Row, Col } from "react-flexbox-grid";

import menuImg from "../images/menu/vietnamese-noodle-bar.jpg";

function VietnameseNoodleBar(props) {
  return (
    <div>
      <NavDecide pageName="vietnameseNoodleBarPage" />
      <div className="subPageContentWrapper menuPage">
        <Row>
          <div className="menuTitle">
            <h1>Vietnamese Noodle Bar</h1>
          </div>
        </Row>
        <Row>
          <img className="img-polaroid" src={menuImg} alt="" />
        </Row>
        <Row>
          <div className="menuBody">
            <h2>Cocktails</h2>
            <h3>Lemongrass Lemonade</h3>
            <p>Fresh lemonade served with lemongrass infused Vodka</p>

            <h3>Cucumber Sake</h3>
            <p>Chilled sake muddled with fresh cucumber</p>

            <h3>Vietnamese Beer</h3>

            <h2>Buffet</h2>
            <h3>Crispy Vietnamese Crepes</h3>
            <p>
              Light crepes served in a bed of butter lettuce with shrimp,
              lemongrass beef and bean sprouts
            </p>

            <h3>Summer Rolls</h3>
            <p>
              Shrimp, rice noodles and Asian vegetables rolled in a rice paper
              wrapper and served with a peanut sauce
            </p>

            <h2>Noodle Bar Items</h2>
            <br/>
            <p>Vermicelli Noodles</p>
            <p>Curried Shrimp</p>
            <p>Curried Shrimp</p>
            <p>Bean Sprouts</p>
            <p>Cucumber</p>
            <p>Shredded Carrots</p>
            <p>Green Papaya</p>
            <p>Cilantro</p>
            <p>Mint</p>
            <p>Thai Basil</p>
            <p>Garlic Peanuts</p>
            <p>Chilies</p>
            <p>Lime</p>
            <p>Sriracha Chile Sauce</p>
            <p>Nuoc Cham Dressing</p>

            <h2>Dessert</h2>
            <h3>Sticky Rice</h3>
            <p>Topped with a coconut cream and fresh mangos</p>
          </div>
        </Row>
      </div>
    </div>
  );
}

export default VietnameseNoodleBar;
