import React, { useState } from "react";
import "./css/press.css";
import NavDecide from "./NavDecide";
import { Grid, Row, Col } from "react-flexbox-grid";

import cmagPdf from "./images/pdf/C Magazine - Showered with Kindness.pdf";

import kindnessImg from "./images/press/kindness.jpg";
import kindnessImg1 from "./images/press/kindness1.jpg";
import flutterPdf from "./images/pdf/Flutter Magazine -  Al Fresco Gathering.pdf";

import flutter1Img1 from "./images/press/flutter1.jpg";
import flutter1Img2 from "./images/press/flutter2.jpg";

import laInBloomImg1 from "./images/press/9308968632_9f4ba1e0cc_z.jpg";
import laInBloomImg2 from "./images/press/9306185377_97110799a4_z.jpg";
import laInBloomImg3 from "./images/press/9306177643_3b210d28e5_z.jpg";

import kinfolkImg1 from "./images/press/la_kinfolk_dinner_1.jpg";
import kinfolkImg2 from "./images/press/la_kinfolk_dinner_23.jpg";
import kinfolkImg3 from "./images/press/la_kinfolk_dinner_20.jpg";

import apolisImg1 from "./images/press/kinfolk-recap-apolis-journal-3.jpg";
import apolisImg2 from "./images/press/la_kinfolk_dinner_41.jpg";

import localRoseImg1 from "./images/press/paper-tart-1024x682.jpg";
import localRoseImg2 from "./images/press/paper-7-1024x682.jpg";

import cMagImg1 from "./images/press/R8VLR.jpg";
import cMagImg2 from "./images/press/yLmiF.jpg";

import laBloomImg1 from "./images/press/8043830951_c54c3eecf1_z.jpg";
import laBloomImg2 from "./images/press/8043828481_334c0f2bf4_z.jpg";
import laBloomImg3 from "./images/press/8043830519_9ff0a236cd_z.jpg";
import laBloomImg4 from "./images/press/8043832251_3aebd31aed_z.jpg";

import cMagImg3 from "./images/press/CMagazine_PPpress_small.jpg";

import dailyCandyImg from "./images/press/dailycandy.jpg";

function Press(props) {
  return (
    <div>
      <NavDecide pageName="pressPage" />

      <div class="subPageContentWrapper pressPage">
        <div class="pressItem">
          <Row>
            <a href={cmagPdf} target="_blank" rel="noopener noreferrer">
              <h1 className="press-headline">
                C Magazine - Showered with Kindness
              </h1>
            </a>
          </Row>
          <Row>
            <a href={cmagPdf} target="_blank" rel="noopener noreferrer">
              <img
                className="img-polaroid"
                width="400px"
                src={kindnessImg1}
                alt=""
              />
              <img
                className="img-polaroid"
                width="800px"
                src={kindnessImg}
                alt=""
              />
            </a>
          </Row>
        </div>

        <div class="pressItem">
          <Row>
            <a href={flutterPdf} target="_blank" rel="noopener noreferrer">
              <h1 class="press-headline">
                Flutter Magazine - Al Fresco Gathering
              </h1>
            </a>
          </Row>
          <Row>
            <a href={flutterPdf} target="_blank" rel="noopener noreferrer">
              <div class="span12 press-image">
                <img
                  class="img-polaroid"
                  width="400px"
                  src={flutter1Img1}
                  alt=""
                />
                <img
                  class="img-polaroid"
                  width="400px"
                  src={flutter1Img2}
                  alt=""
                />
              </div>
            </a>
          </Row>
        </div>

        <div class="pressItem">
          <Row>
            <a
              href="http://lainbloom.blogspot.com/2013/07/whimsical-tea-party-bridal-shower.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h1 class="press-headline">
                LA in Bloom - Whimsical Tea Party Bridal Shower
              </h1>
            </a>
          </Row>
          <Row>
            <a
              href="http://lainbloom.blogspot.com/2013/07/whimsical-tea-party-bridal-shower.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div class="span12 press-image">
                <img
                  class="img-polaroid"
                  width="680px"
                  src={laInBloomImg1}
                  alt=""
                />
                <img
                  class="img-polaroid"
                  width="680px"
                  src={laInBloomImg2}
                  alt=""
                />
                <img
                  class="img-polaroid"
                  width="300px"
                  src={laInBloomImg3}
                  alt=""
                />
              </div>
            </a>
          </Row>
        </div>

        <div class="pressItem">
          <Row>
            <a
              href="http://www.kinfolk.com/kinfolk-dinner-los-angeles/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h1 class="press-headline">
                Kinfolk Magazine - Dinner at Apolis Common Gallery
              </h1>
            </a>
          </Row>
          <Row>
            <a
              href="http://www.kinfolk.com/kinfolk-dinner-los-angeles/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div class="span12 press-image">
                <img
                  class="img-polaroid"
                  width="300px"
                  src={kinfolkImg1}
                  alt=""
                />
                <img
                  class="img-polaroid"
                  width="300px"
                  src={kinfolkImg2}
                  alt=""
                />
                <img
                  class="img-polaroid"
                  width="300px"
                  src={kinfolkImg3}
                  alt=""
                />
              </div>
            </a>
          </Row>
        </div>

        <div class="pressItem">
          <Row>
            <a
              href="http://www.apolisglobal.com/journal/event-kinfolk-dinner-series-los-angeles"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h1 class="press-headline">
                Apolis - Dinner at Apolis Common Gallery
              </h1>
            </a>
          </Row>
          <Row>
            <a
              href="http://www.apolisglobal.com/journal/event-kinfolk-dinner-series-los-angeles"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div class="span12 press-image">
                <img
                  class="img-polaroid"
                  width="300px"
                  src={apolisImg1}
                  alt=""
                />
                <img
                  class="img-polaroid"
                  width="627px"
                  src={apolisImg2}
                  alt=""
                />
              </div>
            </a>
          </Row>
        </div>

        <div class="pressItem">
          <Row>
            <a
              href="http://www.thelocalrose.com/summer-tomato-tarte-recipe-by-danielle-steckler"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h1 class="press-headline">
                The Local Rose - Summer Tomato Tarte Recipe
              </h1>
            </a>
          </Row>
          <Row>
            <a
              href="http://www.thelocalrose.com/summer-tomato-tarte-recipe-by-danielle-steckler"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div class="span12 press-image">
                <img
                  class="img-polaroid"
                  width="400px"
                  src={localRoseImg1}
                  alt=""
                />
                <img
                  class="img-polaroid"
                  width="400px"
                  src={localRoseImg2}
                  alt=""
                />
              </div>
            </a>
          </Row>
        </div>

        <div class="pressItem">
          <Row>
            <a href="http://www.magazinec.com" target="_blank">
              <h1 class="press-headline">
                C Magazine - Twilight Dinner in Rustic Canyon
              </h1>
            </a>
            <a href="http://www.magazinec.com" target="_blank">
              <div class="span12 press-image">
                <img class="img-polaroid" width="400px" src={cMagImg1} alt="" />
                <img class="img-polaroid" width="400px" src={cMagImg2} alt="" />
              </div>
            </a>
          </Row>
        </div>

        <div class="pressItem">
          <Row>
            <a
              href="http://lainbloom.blogspot.com/2012/10/perfume-tea-party.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h1 class="press-headline">LA in Bloom - Perfume Tea Party</h1>
            </a>
          </Row>
          <Row>
            <a
              href="http://lainbloom.blogspot.com/2012/10/perfume-tea-party.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div class="span12 press-image">
                <img
                  class="img-polaroid"
                  width="400px"
                  src={laBloomImg1}
                  alt=""
                />
                <img
                  class="img-polaroid"
                  width="400px"
                  src={laBloomImg2}
                  alt=""
                />
                <img
                  class="img-polaroid"
                  width="400px"
                  src={laBloomImg3}
                  alt=""
                />
                <img
                  class="img-polaroid"
                  width="400px"
                  src={laBloomImg4}
                  alt=""
                />
              </div>
            </a>
          </Row>
        </div>

        <div class="pressItem">
          <Row>
            <a href="http://www.magazinec.com" target="_blank">
              <h1 class="press-headline">C Magazine Indepenedent Epicure</h1>
              <div class="span12 press-image">
                <img class="img-polaroid" width="800px" src={cMagImg3} alt="" />
              </div>
            </a>
          </Row>
        </div>

        <div class="pressItem">
          <Row>
            <a
              href="http://www.dailycandy.com/los-angeles/article/26860/The-Paper-Palate-Catering"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h1 class="press-headline">Daily Candy</h1>
            </a>
          </Row>
          <Row>
            <div class="span12 press-image">
              <a
                href="http://www.dailycandy.com/los-angeles/article/26860/The-Paper-Palate-Catering"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  class="img-polaroid"
                  width="600px"
                  src={dailyCandyImg}
                  alt=""
                />
              </a>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Press;
