import React, { useState } from "react";
import isMobile from "ismobilejs";
import NavBanner from "./NavBanner";
import MobileNavBar from "./MobileNavBar";

function NavDecide(props) {
  if (isMobile.phone) {
    return <MobileNavBar pageName={props.pageName} />;
  } else {
    return <NavBanner pageName={props.pageName} />;
  }
}

export default NavDecide;
