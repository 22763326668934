import React, { useState } from "react";
import "../css/menu.css";
import NavDecide from "../NavDecide";
import { Grid, Row, Col } from "react-flexbox-grid";

import menuImg from "../images/menu/a-mexican-dinner-party.jpg";

function MexicanDinnerParty(props) {
  return (
    <div>
      <NavDecide pageName="mexicanDinnerPartyPage" />
      <div className="subPageContentWrapper menuPage">
        <Row>
          <div className="menuTitle">
            <h1>A Mexican Dinner Party</h1>
          </div>
        </Row>
        <Row>
          <img className="img-polaroid" src={menuImg} alt="" />
        </Row>
        <Row>
          <div className="menuBody">
            <h2>Passed Hors d’oeuvres</h2>
            <h3>Squash Blossom quesadillas</h3>
            <p>
              Handmade corn tortillas filled with Oaxacan cheese and beautiful
              squash blossoms
            </p>

            <h3>Truffeled Oxtail Tartlets</h3>
            <p>
              Braised in Madeira wine with black truffles, these succulent bites
              of tender oxtail meat are served on a bed of pureed carrots
            </p>

            <h3>Scallop Ceviche</h3>
            <p>
              Citrus marinated scallops and roasted red peppers served in a
              spoon
            </p>

            <h2>First Course</h2>
            <h3>Spiced Pumpkin Soup</h3>
            <p>
              Perfect on a chilly evening, this soup is made with sweet pumpkin,
              ginger and cloves and is topped with Mexican crema
            </p>

            <h2>Main Course</h2>
            <h3>Chiles en Nogada</h3>
            <p>
              Poblano chilies stuffed with ground pork, apples, peaches and
              plantains and topped with a fresh walnut sauce and pomegranate
              seeds
            </p>

            <h2>On the Side</h2>
            <h3>Stuffed Chayote</h3>
            <p>A Mexican squash filled with raisins tomatoes and olives</p>

            <h3>Refried Black Beans</h3>
            <p>
              A rustic version of the classic served with crumbled Añejo cheese
            </p>

            <h2>Dessert</h2>
            <h3>Mexican Trio</h3>
            <p>
              A shot of Mexican hot chocolate with a mini churro, passion fruit
              gelée with basil cream and a Oaxacan chocolate brownie
            </p>
          </div>
        </Row>
      </div>
    </div>
  );
}

export default MexicanDinnerParty;
