import React, { useState } from "react";
import "../css/menu.css";
import NavDecide from "../NavDecide";
import { Grid, Row, Col } from "react-flexbox-grid";

import fallDinnerImg from "../images/menu/a-fall-dinner.jpg";

function FallDinner(props) {
  return (
    <div>
      <NavDecide pageName="fallDinnerPage" />
      <div className="subPageContentWrapper menuPage">
        <Row>
          <div className="menuTitle">
            <h1>A Fall Dinner</h1>
          </div>
        </Row>
        <Row>
          <img className="img-polaroid" src={fallDinnerImg} alt="" />
        </Row>
        <Row>
          <div className="menuBody">
            <h2>Welcome Drink</h2>
            <h3>Old Pal</h3>
            <p>Rye, honey, lemon juice and a dash of bitters served over ice</p>

            <h2>Mini Grilled Cheese Bar</h2>

            <h3>Mushroom Gruyere Grilled Cheese</h3>
            <p>
              Fontina, Gruyere, roasted mushrooms, Yukon potatoes, caramelized
              leek and onions and a thyme butter{" "}
            </p>

            <h3>Mozzarella Grilled Cheese</h3>
            <p>
              Mozzarella, fontina, roasted tomatoes, basil-lavender pesto and
              kalamata olive tapenade on whole wheat
            </p>

            <h3>Mousetrap Grilled Cheese</h3>
            <p>
              Tillamook sharp cheddar, creamy havarti, Monterey jack, bacon and
              pickles on sourdough
            </p>

            <h2>Family Style Dinner</h2>

            <h3>Raw Yellow Beet and Goat Cheese Salad</h3>
            <p>
              mixed baby greens with thinly sliced raw beets, goat cheese and a
              cumin vinaigrette
            </p>

            <h3>Moroccan Chicken with Preserved Lemons</h3>
            <p>
              Braised chicken breasts cooked with preserved lemons, prunes and
              green olives
            </p>

            <h3>Mezze Platter</h3>
            <p>Home made hummus, tabouli, baba ganoush and olives</p>

            <h3>Vegetable QUINOA</h3>
            <p>
              Sweet cherry tomatoes, Japanese pumpkin, crimini mushrooms, and
              assorted bell peppers roasted then served over a bed of quinoa
            </p>
          </div>
        </Row>
      </div>
    </div>
  );
}

export default FallDinner;
