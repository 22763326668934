import React from "react";
import "./css/home.css";
import NavDecide from "./NavDecide";
import BackgroundSlider from "react-background-slider";

const splashImageArray = importAll(
  require.context("./images/splash/", false, /\.(png|jpe?g|svg)$/)
);

function Home() {
  return (
    <div>
      <BackgroundSlider images={splashImageArray} duration={3} transition={1} />
      <NavDecide pageName="homePage" />
    </div>
  );
}

function importAll(r) {
  return r.keys().map(r);
}

export default Home;
