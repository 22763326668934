import React, { useState } from "react";
import "./css/gallery.css";
import NavDecide from "./NavDecide";
import GridGallery from "react-grid-gallery";

const thumbnailArray = importAll(
  require.context("./images/gallery/thumbs/", false, /\.(png|jpe?g|svg)$/)
);
const imageArray = importAll(
  require.context("./images/gallery/", false, /\.(png|jpe?g|svg)$/)
);

const imageObjArray = [];
thumbnailArray.forEach((thumbImg, i) => {
  const imgObj = {
    thumbnail: thumbImg,
    src: imageArray[i],
    thumbnailWidth: 250,
    thumbnailHeight: 166
    // caption: "xxx"
  };
  imageObjArray.push(imgObj);
});

function Gallery(props) {
  return (
    <div>
      <NavDecide pageName="galleryPage" />
      <div className="subPageContentWrapper galleryPage">
        <GridGallery
          images={imageObjArray}
          enableImageSelection={false}
          backdropClosesModal={true}
          margin={10}
        />
      </div>
    </div>
  );
}

function importAll(r) {
  return r.keys().map(r);
}

export default Gallery;
