import React, { useState } from "react";
import "./css/about.css";
import NavDecide from "./NavDecide";

import aboutImage from "./images/about.jpg";

function About(props) {
  return (
    <div>
      <NavDecide pageName="aboutPage" />

      <div class="subPageContentWrapper aboutPage">
        <img class="img-polaroid" alt="about" src={aboutImage} />
        <p>
          I was born near Sunset and Vermont in the heart of Hollywood,
          California. Even though I have lived here my whole life, except a few
          years in New York City, I have traveled to many countries. Traveling
          has always been a big part of my life and as a child my parents took
          me everywhere. Years on tour with my dad, I got to see a lot of
          different cultures at an early age and in these travels came exposure
          to many kinds of food. My parents love food and actually everyone in
          my family is an amazing cook. Early on I learned that food and cooking
          were a major part of what held my family together and it was the one
          thing that we all had in common, cooking together every night. My mom
          gave me my first knife when I was five and by eleven I was cooking
          entire meals for everyone.
        </p>

        <p>
          In addition to my worldly exposure through traveling, my parents
          themselves came from very different backgrounds. My father is a New
          York Jew, his roots going back to Romania and Austria, and my mother’s
          family is from Japan. This made for an early exposure to their
          cultures and regional cuisines. My maternal grandfather first came to
          this country at the age of twelve with his father, to work and save
          money to take back to Japan and his first job was working as a cook
          for the milling companies in Washington State. It is there that he met
          my grandmother and soon married her before they moved to East Los
          Angeles in the early 30’s. Shortly thereafter my grandfather and his
          father had won the lottery and with the winnings my great grandfather
          took his half and moved back to Japan. My grandfather took his money
          and bought three produce stores in Downtown L.A. Long story short, my
          grandfather was an amazing cook and I can remember as a child sitting
          on a bar stool in his kitchen, hand-fanning huge bowls of rice to make
          it cool enough for preparing sushi. I was always eager to help him
          cook and I used to fantasize that he was Mister Miyagi, teaching me
          the skills of cooking instead of those of karate. He was magical to me
          as a kid. But sadly when I was twelve he passed away and with him, so
          did my family’s recipes.
        </p>

        <p>
          After graduating college with a BA in film editing my parents asked me
          what I wanted as a graduation gift. I quickly replied “sushi school”.
          For years I had complained to my mom that nobody in our family ever
          made Japanese food and I missed all of my grandfather’s cooking. On
          special occasions he would make sushi, but what I really missed was
          all the country home-cooking that he remembered from his own
          childhood.
        </p>

        <p>
          I went into sushi school thinking I would learn some traditional
          Japanese cooking and then go back into editing, which I did for a few
          years, but then one day, left my office, walked into a Japanese
          restaurant and asked for a job. The head chef misunderstood what I had
          said about my experience, which was none, and hired me to begin work
          there the next day. We joke around about it now, but he didn’t find
          out the truth about my experience until a year after I had been
          working there. I guess you can say I was a natural. I loved it. Six
          nights a week I would stay up all night drinking with the other chefs
          until 4 am, then head down to the markets to pick the fish to be
          served that night at the restaurant. I remember Nobu Matsuhisa
          standing next to me fighting for the same loin of tuna. It was an
          unforgettable experience and I will always cherish my seven years as a
          sushi chef.
        </p>

        <p>
          My background in Japanese cuisine has taught me that in order to
          prepare the best dishes you must be precise in the freshness and
          seasoning of every single ingredient. With most catering, the food has
          to be prepared days ahead of time and this concept never felt right to
          me, turning me off to the notion of “pre-prepared” food. So my
          resolution to this… a year ago I began my own business with the
          objective of only cooking with the freshest and highest quality of
          ingredients. My relationships with small food purveyors and wineries
          are especially important to me in my quest to know exactly what goes
          into everything I serve.
        </p>

        <p>
          At Paper Palate we understand the stress that a host goes through when
          throwing a party and our goal is to allow you, the host, to enjoy the
          company of your guests without having all the pressures of the
          evening. I want you to sit down to my dinners and be transported by
          entire experience. Our mission is to create a perfect party with our
          wide array of food, home-infused elixirs, fine wine, décor and music.
          I wholly believe that food and the act of eating is an experience to
          be savored and enjoyed, and only then will it become a part of your
          cherished memories.
        </p>

        <p>
          Each one of my recipes is from the heart. Every dish is delicious and
          truly homemade, and many of our vegetables come from my own organic
          garden. Paper Palate is an experience that will convince you that food
          truly is a form of art.
        </p>

        <p>
          I hope we will get to meet soon so I can cook for you and show you how
          we do it Paper Palate way.
        </p>

        <p>Cheers! Danielle</p>
      </div>
    </div>
  );
}

export default About;
