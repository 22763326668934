import React, { useState } from "react";
import "./css/navBanner.css";

function NavBanner(props) {
  // Declare a new state variable, which we'll call "count"
  // const [count, setCount] = useState(0);

  return (
    <div
      class={`navBanner ${props.pageName !== "homePage" ? "subPage" : ""} ${
        props.pageName ? props.pageName : ""
      }`}
    >
      <h1 class="logo">
        <a href="/">Paper Palate</a>
      </h1>
      <nav class="main-nav">
        <ul>
          <li class={props.pageName === "homePage" ? "selected" : ""}>
            <a href="/">Home</a>
          </li>
          <li class={props.pageName === "aboutPage" ? "selected" : ""}>
            <a href="/about">About</a>
          </li>
          <li class={props.pageName === "galleryPage" ? "selected" : ""}>
            <a href="/gallery">Gallery</a>
          </li>
          <li class={props.pageName === "pressPage" ? "selected" : ""}>
            <a href="/press">Press</a>
          </li>
          <li class={props.pageName === "contactPage" ? "selected" : ""}>
            <a href="/contact">Contact</a>
          </li>
          <li>
            <a href="http://instagram.com/paperpalate" target="_blank">
              Instagram
            </a>
          </li>
        </ul>
      </nav>
      <br />
      <nav class="sub-nav">
        <ul>
          <li class={props.pageName === "fallDinnerPage" ? "selected" : ""}>
            <a href="/a-fall-dinner">A Fall Dinner</a>
          </li>
          <li
            class={props.pageName === "warmWinterNightPage" ? "selected" : ""}
          >
            <a href="/a-warm-winter-night">A Warm Winter Night</a>
          </li>
          <li
            class={
              props.pageName === "passedHorsDoeuvresPartyPage" ? "selected" : ""
            }
          >
            <a href="/passed-hors-doeuvres-party">
              Passed Hors d’Oeuvres Party
            </a>
          </li>
          <li class={props.pageName === "springEveningPage" ? "selected" : ""}>
            <a href="/a-spring-evening">A Spring Evening</a>
          </li>
          <li class={props.pageName === "japaneseDinnerPage" ? "selected" : ""}>
            <a href="/a-japanese-dinner">A Japanese Dinner</a>
          </li>
          <li
            class={
              props.pageName === "vietnameseNoodleBarPage" ? "selected" : ""
            }
          >
            <a href="/vietnamese-noodle-bar">Vietnamese Noodle Bar</a>
          </li>
          <li
            class={
              props.pageName === "mexicanDinnerPartyPage" ? "selected" : ""
            }
          >
            <a href="/a-mexican-dinner-party">A Mexican Dinner Party</a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default NavBanner;
