import React, { useState } from "react";
import "./css/mobileNavBar.css";
import { FaBars, FaInstagram, FaAt } from "react-icons/fa";
import { IoIosAt } from "react-icons/io";
// import InstagramIcon from "./images/instagram-icon.svg";
import AtIcon from "./images/at-icon1.png";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

function MobileNavbar(props) {
  return (
    <Navbar bg="light" expand="lg" fixed="top">
      <Navbar.Brand href="/">PAPER PALATE</Navbar.Brand>

      <Navbar.Brand href="http://instagram.com/paperpalate">
        <FaInstagram className="ig-icon" size={30} />
      </Navbar.Brand>

      <Navbar.Brand href="/contact">
        <IoIosAt className="at-icon" size={35} />
      </Navbar.Brand>

      <Navbar.Toggle id="responsive-navbar-navx">
        <FaBars className="menu-bars-icon" size={30} />
      </Navbar.Toggle>

      <Navbar.Collapse id="responsive-navbar-nav">
        <div id="nav-text">
          <Nav className="mr-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="/gallery">Gallery</Nav.Link>
            <Nav.Link href="/press">Press</Nav.Link>
            {/* <Nav.Link href="/contact">Contact</Nav.Link> */}

            <Nav.Link href="/a-fall-dinner">A Fall Dinner</Nav.Link>
            <Nav.Link href="/a-warm-winter-night">A Warm Winter Night</Nav.Link>
            <Nav.Link href="/passed-hors-doeuvres-party">
              Passed Hors d’Oeuvres Party
            </Nav.Link>
            <Nav.Link href="/a-spring-evening">A Spring Evening</Nav.Link>
            <Nav.Link href="/a-japanese-dinner">A Japanese Dinner</Nav.Link>
            <Nav.Link href="/vietnamese-noodle-bar">
              Vietnamese Noodle Bar
            </Nav.Link>
            <Nav.Link href="/a-mexican-dinner-party">
              A Mexican Dinner Party
            </Nav.Link>
          </Nav>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default MobileNavbar;
