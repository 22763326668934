import React, { useState } from "react";
import "../css/menu.css";
import NavDecide from "../NavDecide";
import { Grid, Row, Col } from "react-flexbox-grid";

import menuImg from "../images/menu/passed-hors-doeuvres-party.jpg";

function PassedHorsDoeuvresParty(props) {
  return (
    <div>
      <NavDecide pageName="passedHorsDoeuvresPartyPage" />
      <div className="subPageContentWrapper menuPage">
        <Row>
          <div className="menuTitle">
            <h1>Passed Hors d’Oeuvres Party</h1>
          </div>
        </Row>
        <Row>
          <img className="img-polaroid" src={menuImg} alt="" />
        </Row>
        <Row>
          <div className="menuBody">
            <h3>Spring Farro</h3>
            <p>
              Tossed with fennel, spring peas and feta cheese and served in a
              spoon
            </p>

            <h3>Pumpkin tacos</h3>
            <p>
              Mini corn tortillas filled with roasted spiced pumpkin, chili and
              cabbage slaw, pepitas and feta cheese
            </p>

            <h3>Empanadas Chicas</h3>
            <p>Little empanadas served with manchego cheese and leeks</p>

            <h3>Stuffed Dates</h3>
            <p>
              Medjool dates stuffed with slivers of Parmesan cheese, walnuts and
              honey
            </p>

            <h3>Hamachi Wrapped in Shiso</h3>
            <p>
              Japanese yellowtail thinly sliced then sprinkled with lemon juice,
              sea salt and jalapeno
            </p>

            <h3>Mac n’ Cheese</h3>
            <p>Bits of lobster baked with French cheeses in a mini ramekin</p>

            <h3>Vietnamese Tea Sandwiches</h3>
            <p>
              Lemongrass chicken on baguette with cilantro and fresh pickles
            </p>

            <h3>Persimmon with Serrano Ham and Arugula</h3>
            <p>
              Crisp persimmon tossed with Sherry vinegar and cumin wrapped in
              Serrano ham with a arugula sprig
            </p>

            <h3>Mini Burgers</h3>
            <p>
              Buffalo burgers served on a bun with raw milk cheddar cheese and
              bacon (can be done protein style wrapped in lettuce)
            </p>

            <h3>Oyster Mushrooms Wrapped in Filet</h3>
            <p>With a au jus reduction</p>

            <h3>Fresh Strawberry Tartlets</h3>
            <p>
              Mini tarts filled with crème fraîche and brown sugar and topped
              with sliced strawberries
            </p>

            <h3>Ginger Snap Cookies Filled with Dulce de Leche</h3>
          </div>
        </Row>
      </div>
    </div>
  );
}

export default PassedHorsDoeuvresParty;
