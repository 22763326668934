import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";

import Home from "./Home";
import About from "./About";
import Gallery from "./Gallery";
import Press from "./Press";
import Contact from "./Contact";
import FallDinner from "./subPages/FallDinner";
import WarmWinterNight from "./subPages/WarmWinterNight";
import PassedHorsDoeuvresParty from "./subPages/PassedHorsDoeuvresParty";
import SpringEvening from "./subPages/SpringEvening";
import JapaneseDinner from "./subPages/JapaneseDinner";
import VietnameseNoodleBar from "./subPages/VietnameseNoodleBar";
import MexicanDinnerParty from "./subPages/MexicanDinnerParty";

const routing = (
  <Router>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/about" component={About} />
      <Route path="/gallery" component={Gallery} />
      <Route path="/press" component={Press} />
      <Route path="/contact" component={Contact} />
      <Route path="/a-fall-dinner" component={FallDinner} />
      <Route path="/a-warm-winter-night" component={WarmWinterNight} />
      <Route
        path="/passed-hors-doeuvres-party"
        component={PassedHorsDoeuvresParty}
      />
      <Route path="/a-spring-evening" component={SpringEvening} />
      <Route path="/a-japanese-dinner" component={JapaneseDinner} />
      <Route path="/vietnamese-noodle-bar" component={VietnameseNoodleBar} />
      <Route path="/a-mexican-dinner-party" component={MexicanDinnerParty} />
    </Switch>
  </Router>
);

ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
