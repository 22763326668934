import React, { useState } from "react";
import "./css/contact.css";
import NavDecide from "./NavDecide";
import { FaMobileAlt, FaInstagram, FaEnvelope } from "react-icons/fa";
// import GoogleMapComponent from "./GoogleMapComponent";

import { Grid, Row, Col } from "react-flexbox-grid";

function Contact(props) {
  return (
    <div>
      <NavDecide pageName="contactPage" />
      <div class="subPageContentWrapper contactPage">
        <Row>
          <h4>
            <FaEnvelope className="mobile-icon" />
            <a href="mailto:info@paperpalate.com">info@paperpalate.com</a>
          </h4>
        </Row>
        <Row>
          <h4>
            <FaMobileAlt className="mail-icon" />
            <a href="tel:1-310-963-0720">(310) 963-0720</a>
          </h4>
        </Row>
        <Row>
          <h4>
            <FaInstagram className="ig-icon" />
            <a
              href="http://instagram.com/paperpalate"
              target="_blank"
              rel="noopener noreferrer"
            >
              INSTAGRAM
            </a>
          </h4>
        </Row>
        {/* <Row>
          <GoogleMapComponent
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCAe8DIEgN5FgrzkVHAB8fakRnQyEYnMjI&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </Row> */}
      </div>
    </div>
  );
}

export default Contact;
