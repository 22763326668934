import React, { useState } from "react";
import "../css/menu.css";
import NavDecide from "../NavDecide";
import { Grid, Row, Col } from "react-flexbox-grid";

import menuImg from "../images/menu/a-warm-winter-night.jpg";

function WarmWinterNIght(props) {
  return (
    <div>
      <NavDecide pageName="warmWinterNightPage" />
      <div className="subPageContentWrapper menuPage">
        <Row>
          <div className="menuTitle">
            <h1>A Warm Winter Night</h1>
          </div>
        </Row>
        <Row>
          <img className="img-polaroid" src={menuImg} alt="" />
        </Row>
        <Row>
          <div className="menuBody">
            <h2>Appetizers</h2>
            <h3>Pickled Quail Eggs</h3>
            <p>Served with kuro ichimi</p>

            <h3>Dungeness Crab Salad</h3>
            <p>
              Served with Japanese cucumbers, endive, radicchio and yamaimo in a
              rice wine and grapefruit vinaigrette
            </p>

            <h3>Hirame</h3>
            <p>
              Japanese white fish specially served for New Year’s in a spoon
              with lime, sea salt and rare rocoto chili sauce
            </p>

            <h2>Palate Cleanser</h2>
            <h3>Suimono</h3>
            <p>
              Forest mushrooms served in a clear broth with a hint Yuzu from my
              garden
            </p>

            <h2>Main Courses</h2>
            <h3>Fried Basa</h3>
            <p>Saffron aioli and homemade marmalade</p>
            <h3>Pot Roast</h3>
            <p>
              Served with braised winter squashes on a bed of pureed rutabaga
              and topped with kale crisps
            </p>

            <h2>Dessert</h2>
            <h3>Almond Cake</h3>
            <p>With blood orange syrup and fresh strawberries</p>
          </div>
        </Row>
      </div>
    </div>
  );
}

export default WarmWinterNIght;
