import React, { useState } from "react";
import "../css/menu.css";
import NavDecide from "../NavDecide";
import { Grid, Row, Col } from "react-flexbox-grid";

import menuImg from "../images/menu/a-japanese-dinner.jpg";

function JapaneseDinner(props) {
  return (
    <div>
      <NavDecide pageName="japaneseDinnerPage" />
      <div className="subPageContentWrapper menuPage">
        <Row>
          <div className="menuTitle">
            <h1>A Japanese Dinner</h1>
          </div>
        </Row>
        <Row>
          <img className="img-polaroid" src={menuImg} alt="" />
        </Row>
        <Row>
          <div className="menuBody">
            <h2>Zensai</h2>
            <h3>Dungeness Crab Salad</h3>
            <p>
              Served with Japanese cucumbers, endive, radicchio and yamaimo in a
              rice wine vinaigrette
            </p>

            <h2>Saimono</h2>
            <h3>Matsutake Mushroom & Sea Bream Soup</h3>
            <p>Served in a clear broth with a hint of Japanese Yuzu</p>

            <h2>Sashimi</h2>
            <h3>Japanese Tai Fish</h3>
            <p>Thinly sliced and topped with ume, yuzo kosho</p>

            <h2>Yakimono</h2>
            <h3>Broiled Miso Black Cod</h3>

            <h2>Nimono</h2>
            <h3>Simmered Diakon Radish</h3>
            <p>With a white Miso Sauce</p>

            <h2>Agenomo</h2>
            <h3>Minced Mackerel and Ginger</h3>
            <p>
              Wrapped in a shiso leaf served tempura style with a sprinkling of
              sea salt
            </p>

            <h2>Gohan</h2>
            <h3>Chestnut Rice</h3>
            <p>Topped with Mitsuba</p>

            <h2>Sunomono</h2>
            <h3>Assorted Freshly Made Pickles</h3>

            <h2>Ryokucha</h2>
            <h3>Imported Japanese Green Tea</h3>
            <p>Served with roasted persimmon and spiced mascarpone cheese</p>
          </div>
        </Row>
      </div>
    </div>
  );
}

export default JapaneseDinner;
