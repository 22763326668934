import React, { useState } from "react";
import "../css/menu.css";
import NavDecide from "../NavDecide";
import { Grid, Row, Col } from "react-flexbox-grid";

import menuImg from "../images/menu/a-spring-evening.jpg";

function SpringEvening(props) {
  return (
    <div>
      <NavDecide pageName="springEveningPage" />
      <div className="subPageContentWrapper menuPage">
        <Row>
          <div className="menuTitle">
            <h1>A Spring Evening</h1>
          </div>
        </Row>
        <Row>
          <img className="img-polaroid" src={menuImg} alt="" />
        </Row>
        <Row>
          <div className="menuBody">
            <h2>Signature Drinks</h2>
            <h3>Raspberry Herb Cocktail</h3>
            <p>
              Basil and mint infused vodka muddled with raspberries and lemon
              juice then strained and topped off with Prosecco and a fresh
              raspberry and basil garnish
            </p>

            <h2>Passed Bruschetta</h2>
            <h3>Chicken Livers</h3>
            <p>
              Lemony chicken livers sautéed with capers, parsley and smoked
              bacon
            </p>

            <h3>Roasted Fava Beans</h3>
            <p>
              Thin slices of Japanese eggplant grilled then marinated with
              spices and herbs
            </p>

            <h3>Eggplant Carpaccio</h3>
            <p>
              Thin slices of Japanese eggplant grilled then marinated with
              spices and herbs
            </p>

            <h2>First Course</h2>
            <h3>Insalata Puttanesca</h3>
            <p>Farmer’s market bitter greens in an anchovy dressing</p>

            <h2>Main Course</h2>
            <h3>Sausage Over Torn Pasta</h3>
            <p>
              Our homemade fennel and red pepper sausage served on a bed of
              Paper Palate hand torn pasta and rapini
            </p>

            <h2>Dessert</h2>
            <h3>Free Form Pie</h3>
            <p>
              Fresh peaches, raspberries, plums and blueberries baked in a short
              crust pastry and served with brown bread ice cream
            </p>
          </div>
        </Row>
      </div>
    </div>
  );
}

export default SpringEvening;
